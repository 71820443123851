import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/footer"
import GridItemWithHeading from "../components/grid-item-with-heading"
import Header from "../components/header"
import SEO from "../components/seo"
import TeamProfile from "../components/team-profile"
import Pagination from "../components/pagination"
// import "../styles/index.scss"
import GridItemQuote from "../components/grid-item-quote"
import Img from "gatsby-image"
import teamProfileStyles from "../components/team-profile.module.scss"

export default class MeetTheTeamTemplate extends React.Component {
  render() {
    const { image1, title, fillerImage, quote, description, metaImage } = {
      ...this.props.data.meetTheTeam.frontmatter,
    }
    const { currentPage, numPages } = { ...this.props.pageContext }
    const teamSingles = this.props.data.teamSingles.edges
    const isPage2 = currentPage === 2
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-2-2">
          <div className="grid-item-1x2">
            <GridItemWithHeading
              image={image1.childImageSharp.fluid}
              heading={title}
            ></GridItemWithHeading>
          </div>
          <div className="grid-item-4-4">
            {teamSingles.map((teamMember, index) => {
              return (
                <TeamProfile
                  key={index}
                  teamMember={teamMember}
                  index={index}
                ></TeamProfile>
              )
            })}
            {isPage2 && (
              <div className={teamProfileStyles.teamProfile}>
                <Img fluid={fillerImage.childImageSharp.fluid} />
              </div>
            )}
            {isPage2 && (
              <div
                className={`${teamProfileStyles.teamProfile} ${teamProfileStyles.teamProfileQuote}`}
              >
                <GridItemQuote quote={quote}></GridItemQuote>
              </div>
            )}
          </div>
          <Pagination
            classModifier="meetTheTeamPagination"
            currentPage={currentPage}
            numPages={numPages}
            baseUrl="meet-the-team"
          ></Pagination>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// TODO Seperate graphql file? Sharing images on other templates?
export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    meetTheTeam: markdownRemark(
      frontmatter: { templateKey: { eq: "meet-the-team" } }
    ) {
      frontmatter {
        image1 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        fillerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        quote
        description
      }
    }
    teamSingles: allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { templateKey: { eq: "teamSingle" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            headShot {
              childImageSharp {
                original {
                  height
                }
                fixed {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            title
            description
            imagePosition
            metaImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
