import { Link } from "gatsby"
import React from "react"
import styles from "./pagination.module.scss"

export default class Pagination extends React.Component {
  renderPagination() {
    const { currentPage, numPages, baseUrl, classModifier } = this.props
    const pagination = []
    for (let index = 0; index < numPages; index++) {
      const link = index === 0 ? "" : index + 1
      const display = index + 1
      pagination.push(
        <li
          key={index}
          className={currentPage === display ? styles.current : ""}
        >
          <Link to={`/${baseUrl}/${link}`} key={display}>
            {display}
          </Link>
        </li>
      )
    }

    return (
      <ul className={`${styles.pagination} ${styles[classModifier]} box`}>
        {pagination}
      </ul>
    )
  }
  render() {
    return this.renderPagination()
  }
}
